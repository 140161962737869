import { h } from 'preact';
import { Router } from 'preact-router';
import { AuthProvider } from 'contexts/auth-context';
import { VideoProvider } from 'contexts/video-context';

// Code-splitting is automated for `routes` directory
import Home from 'routes/home';
import Capture from 'routes/capture';
import Review from 'routes/review';

const App = () => (
	<div id="app" class="flex flex-col max-w-screen-md h-screen mx-auto overflow-y-scroll bg-white">
		<AuthProvider>
			<VideoProvider>
				<Router>
					<Home path="/" />
					<Capture path="/capture" />
					<Review path="/review" />
				</Router>
			</VideoProvider>
		</AuthProvider>
	</div>
)

export default App;
