import { useState } from 'preact/hooks';
import constate from 'constate';

const SUPPORTED_VIDEO = ['mov', 'avi', 'wmv', 'flv', '3gp', 'mp4', 'mpg'];

const formatTitle = rawTitle => {
  const cleanedTitle = SUPPORTED_VIDEO.reduce((memo, ext) => {
    return memo.replace(`.${ext}`, '');
  }, rawTitle);

  return cleanedTitle;
}

const PLAYBACK_RATES_GRAPH = {
  0.25: 1,
  0.5: 0.25,
  0.75: 0.5,
  1: 0.75,
};

const useVideoContext = () => {
  const [videoUrl, setVideoUrl] = useState(null);
	const [videoType, setVideoType] = useState(null);
	const [videoTitle, setVideoTitle] = useState('');
	const [isPlaying, setIsPlaying] = useState(true);
	const [isMuted, setIsMuted] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);

  const togglePlay = () => {
		setIsPlaying(oldIsPlaying => !oldIsPlaying);
	}

	const toggleMute = () => {
		setIsMuted(oldIsMute => !oldIsMute);
	}

  const setVideo = options => {
    if (!options) {
      setVideoUrl(null);
      setVideoType(null);
      setVideoTitle('');
      return;
    }

    const { url, type, title } = options;
    if (url) {
      setVideoUrl(url);
    }
    if (type) {
      setVideoType(type);
    }
    if (title) {
      setVideoTitle(formatTitle(title));
    }
  }

  const setPlayback = () => {
    setPlaybackRate(oldPlayback => {
      return PLAYBACK_RATES_GRAPH[oldPlayback];
    });
  }

  const resetPlayback = () => {
    setPlaybackRate(1);
  }

  return {
    videoUrl,
    videoType,
    videoTitle,
    setVideo,
    isPlaying,
    isMuted,
    togglePlay,
    toggleMute,
    setPlayback,
    resetPlayback,
    playbackRate,
  }
};

const [VideoProvider, useVideo] = constate(useVideoContext);

export {
  VideoProvider,
  useVideo,
}
