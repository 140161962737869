import { useState } from 'preact/hooks';
import constate from 'constate';

const TEST_VIDEO = {
  id: 1,
  title: 'Tsitsipas Backhand',
  url: 'https://www.youtube.com/watch?v=-irKShkVeQQ',
  type: 'youtube',
  notes: 'Smooooooth',
  tags: ['Backhand', 'Side View', 'Reference'],
  createdAt: new Date(),
};

const useAuthContext = () => {
  const [user, setUser] = useState({
    sessions: [TEST_VIDEO],
  });

  // TODO: this will be handled by a POST /sessions call
  // when I implement the backend
  const createSession = async session => {
    const keys = ['title', 'url', 'notes', 'tags'];
    if (!keys.every(key => key in session)) {
      throw new Error(`Could not create session. Must have the following keys: "${keys.join(', ')}"`);
    }

    session.id = user.sessions.length + 1;
    session.createdAt = new Date();
    setUser(oldUser => {
      return {
        ...oldUser,
        sessions: oldUser.sessions.concat(session).sort((a, b) => b.createdAt - a.createdAt)
      }
    });

    return session;
  }

  return {
    user,
    createSession,
  }
};

const [AuthProvider, useAuth] = constate(useAuthContext);

export {
  AuthProvider,
  useAuth,
}
